import React, { useEffect, useState } from "react";
import WelcomeBanner from "../components/WelcomeBanner";
import Product from "../components/Product";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../actions/productActions";
import CookieConsent from "react-cookie-consent";
import { Link, useParams, useHistory } from "react-router-dom";

export default function HomeScreen() {
  const { pageNumber = 1 } = useParams();
  const history = useHistory();
  
  const [section, setSection] = useState("offro");
  const [pageBySection, setPageBySection] = useState({
    offro: 1,
    cerco: 1,
    propongo: 1,
    avviso: 1,
    dono: 1
  });

  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, pages, page } = productList;

  // Update stored page number when pageNumber changes from URL
  useEffect(() => {
    setPageBySection(prev => ({
      ...prev,
      [section]: Number(pageNumber)
    }));
  }, [pageNumber]);

  // Fetch products and sync URL when section or its page changes
  useEffect(() => {
    dispatch(
      listProducts({
        pageNumber: pageBySection[section],
        section: section
      })
    );
    history.replace(`/pageNumber/${pageBySection[section]}`);
  }, [dispatch, section, pageBySection[section], history]);

  // Handle section change
  const handleSectionChange = (newSection) => {
    if (newSection !== section) {
      setSection(newSection);
      // Use the stored page number for the new section
      const targetPage = pageBySection[newSection];
      history.push(`/pageNumber/${targetPage}`);
    }
  };

  return (
    <div>
      <WelcomeBanner />
      <CookieConsent
        location="bottom"
        buttonText="Accetto"
        cookieName="pagineazzurre"
        style={{ background: "#626360" }}
        buttonStyle={{
          color: "#626360",
          fontSize: "15px",
          marginRight: "150px",
        }}
        expires={150}
      >
        {/* Cookie consent content */}
      </CookieConsent>
      <div>
        <h1 className="row center">ULTIME ATTIVITÀ</h1>
        <div className="row center">
          <button
            className={section === "offro" ? "filter active" : "filter"}
            onClick={() => handleSectionChange("offro")}
          >
            Offerte
          </button>
          <button
            className={section === "cerco" ? "filter active" : "filter"}
            onClick={() => handleSectionChange("cerco")}
          >
            Richieste
          </button>
          <button
            className={section === "propongo" ? "filter active" : "filter"}
            onClick={() => handleSectionChange("propongo")}
          >
            Proposte
          </button>
          <button
            className={section === "avviso" ? "filter active" : "filter"}
            onClick={() => handleSectionChange("avviso")}
          >
            Avvisi
          </button>
          <button
            className={section === "dono" ? "filter active" : "filter"}
            onClick={() => handleSectionChange("dono")}
          >
            Dono/Tempo
          </button>
        </div>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            {products.length === 0 && <MessageBox>No Product Found</MessageBox>}
            <div className="row center cards-container">
              {products.map((product) => (
                product.section === section && !product.pause && (
                  <Product key={product._id} product={product}></Product>
                )
              ))}
            </div>
            <div className="row center pagination">
              {[...Array(pages).keys()].map((x) => (
                <Link
                  className={x + 1 === pageBySection[section] ? 'active' : ''}
                  key={x + 1}
                  to={`/pageNumber/${x + 1}`}
                >
                  {x + 1}
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
